<template>
  <div>
  </div>
</template>
<script>
import {getOpenId} from '../utils/api'
export default {
  data() {
    return {
    };
  },

  mounted() {
    document.title = '登录';
    if (this.$route.query.code) {
      this.getOpenid();
    } else {
      let appId = "wx20508d66c240b36a";
      let local = window.location.href;
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        appId +
        "&redirect_uri=" +
        encodeURIComponent(local) +
        "&response_type=code&scope=snsapi_base&state=1#wechat_redirect";
    }
  },
  methods:{
    //获取openid
    getOpenid() {
      let params = {
        code: this.$route.query.code,
        channelId: "wx20508d66c240b36a",
      };
      getOpenId(params).then((res) => {
        sessionStorage.setItem('openid',res.result.openId)
        this.$router.push({
          path:'/index',
          query:{
            id:this.$route.query.id
          }
        })
      });
    },
  }
};
</script>
 
<style lang="scss" scoped>

</style>